<template>
    <div>
        <Head></Head>
        <div>
            <Loader></Loader>
        </div>
        <div class="bg-orange-50">
            <header>
                <Header></Header>
            </header>

            <div
                class="mt-4 sm:mt-2 max-w-full mx-auto px-4 md:px-6 lg:max-w-7xl lg:px-8"
                style="min-height: 90vh"
            >
                <slot />
            </div>
            <TheModals />
            <footer>
                <Footer />
            </footer>
            <SlotDialog></SlotDialog>
            <SlotOrder></SlotOrder>
            <Alert />
            <client-only>
                <checkInternet></checkInternet>
            </client-only>
<!--            <PromoMomy />-->
            <PromoVacc/>
            <!--<Promo/>-->
            <!--<PromoPrice/>-->
            <!--<PromoFlu v-if="clinicsStore?.clinic?.attributes?.region === 'msk' || clinicsStore?.clinic?.attributes?.region === 'spb'"/>-->

            <AppDialog></AppDialog>
        </div>
    </div>
</template>

<script>
import Loader from "../components/Loader";
import { useClinics } from "~/store/clinics";
import Alert from "../components/Alert";
import Dialog from "../components/Slot/Dialog";
import cookieHelper from "~/libs/region";
import { useRegion } from "~/store/app/region";

export default {
    async setup() {
        const clinicsStore = useClinics();
        const regionStore = useRegion();
        const route = useRoute();
        useHead(() => ({
            link: [
                {
                    rel: "canonical",
                    href: "https://kids.fomin-clinic.ru" + route.path
                }
            ]
        }));

        if (!clinicsStore.listClinics.length) {
            await clinicsStore.fetchClinics();
        }

        const regionsWithClinics = clinicsStore.regionsWithClinics;
        const listRegions = clinicsStore.listRegions;

        return {
            clinicsStore,
            regionsWithClinics,
            listRegions,
            regionStore
        };
    },
    async mounted() {
        const build_ver = await fetch("/ver.info").then((response) =>
            response.text()
        );
        const ver = useCookie("ver");
        ver.value = build_ver;

        this.setRegionFromUrl();
        await this.getClinicId();
        if (cookieHelper.hasRegionId()) {
            this.regionStore.regionId = cookieHelper.getRegionId();
        }
    },
    methods: {
        goHome() {
            window.location = window.location;
        },
        setRegionFromUrl() {
            const regionStore = useRegion();
            const urlParams = new URLSearchParams(window.location.search);
            const regionId = urlParams.get("region");
            if (regionId) {
                cookieHelper.setRegionId(regionId);
                regionStore.setRegion();
            }
        },
        async getClinicId() {
            function getRegionClinicIdFromUrl() {
                const urlParams = new URLSearchParams(window.location.search);
                const regionParam = urlParams.get("region");

                switch (regionParam) {
                    case "ufa":
                        return "6e7ffdd9-5667-11ec-bb0f-d0e4dab58074";
                    case "krr":
                        return "2dbf05af-3186-11eb-94f2-6ab5df5ca2f5";
                    case "msk":
                        return "2fa0c897-bbd8-11ea-b901-dcf5058b959a";
                    case "sochi":
                        return "f94b5f03-65ad-11ed-953d-d01dbecae8b6";
                    case "spb":
                        return "74af85c9-5700-11eb-9505-3a56c320acf4";
                    default:
                        return null;
                }
            }

            let clinicId = getRegionClinicIdFromUrl();


            if (clinicId) {
                window.localStorage.setItem("clinicId", clinicId);
                this.clinicsStore.setClinicId(clinicId);
                this.clinicId = clinicId;

                function removeRegionParam() {
                    const currentUrl = window.location.href;
                    const url = new URL(currentUrl);
                    url.searchParams.delete("region");
                    const updatedUrl = url.toString();
                    window.history.replaceState({}, document.title, updatedUrl);
                }

                removeRegionParam();
            } else {
                if (window.localStorage) {
                    let clinicId = window.localStorage.getItem("clinicId");

                    if (clinicId === "43c0eb72-7224-11ed-953e-be947ca7f82c") {
                        clinicId = "2fa0c897-bbd8-11ea-b901-dcf5058b959a";
                    }

                    if (clinicId) {
                        this.clinicsStore.setClinicId(clinicId);
                        this.clinicId = clinicId;
                    } else {
                        this.open = true;
                    }
                }
            }
        }
    },
    components: { Dialog, Alert, Loader }
};
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(0.1rem);
}
</style>
